import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "gatsby-image"
import { rhythm } from "../utils/typography"
import HeadShot from "../../content/assets/profile-pic.jpg"
import MugShot from "../../content/assets/worlds-best-speaker.jpg"

class WorkshopPage extends React.Component
{
    render() {
        const { data } = this.props
        const siteTitle = data.site.siteMetadata.title
        const menu = data.site.siteMetadata.menuLinks
        const tagline = data.site.siteMetadata.tagline
    
        return (
          <Layout location={this.props.location} title={siteTitle} menuLinks={menu} tagline={tagline}>
            <SEO title="Derek's speaking & teaching workshops" />
            


            <h1>Workshops with Derek</h1>
            <p class="pub-details">Be a better speaker by being more like a teacher.</p>

            <img 
              alt="Profile photo of Derek speaking at a conference, wearing a discreet over the ear microphone"
              className="bodyshot"
              src={HeadShot}

            />
            <p><em>If you want to become a better speaker, a better workshop leader, or better presenter, Derek can help you. He's been teaching and speaking for 27 years. He's a world-renowned conference speaker and author in the web accessibility and inclusive design field. He's established a reputation for creative, hands-on workshops that help people not only learn new information, but also build new skills.</em></p>

            <h2>Current workshops</h2>
            
            <h3>Teach, don't speak: the workshop</h3>

            <p>The equivalent of a full-day workshop, split into 4 parts, and delivered online. This workshop shares the framework that Derek uses to plan every conference talk, every workshop, and every professional development session he delivers. The workshop is hands-on. You'll be expected to work to build your skills by practicing them live with other participants and sharing them with each other. You'll also get 1:1 time with Derek in his private slack to discuss things. And stuff.</p>

            <p>The March/April 2020 Teach don't speak workshop is closed for registration. Join my mailing list below to get notified of the next live workshop.</p>
            
            
            <h2>Other speaking and teaching things</h2>
            
            <p>Sign up for this mailing list to let me know you're interested in these kinds of speaker/teacher type things. When you sign up, here's what'll happen:</p>

            <ol>
              <li>Within 2 hours of your confirming you want to be on the list, I'll send you a draft outline of what the course includes, and ask for your feedback on it, and what topics you might want to learn about.</li>
              <li>For the next 4 or 5 days, I'll send you some new content every day or so, that almost serves as a "preview" of the content for the course. I'll also likely ask you some more questions.</li>
            </ol>

            <a className="mc-cta" href="https://mailchi.mp/f133934f2b0d/feather">Learn more via MailChimp</a>

            <h2>That's it...</h2>

            <p>If you have questions, I'm here to answer them. Honestly -- DM on twitter is likely best, but I'm happy to receive emails too. My first name @ this domain will work.</p>

          </Layout>
        )
      }

}

export default WorkshopPage

export const pageQuery = graphql`
  query {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    mugshot: file(absolutePath: { regex: "/worlds-best-speaker.jpg/" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        title
        tagline
        menuLinks {
          name
          link
        }
      }
    }
  }
`